import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';
import { Spin as Hamburger } from 'hamburger-react'

import LanguageSwitch from 'elements/LanguageSwitch';
import MultiLevelMenu from 'elements/MultiLevelMenu.js';

import { getLanguage, getTranslation } from 'utils/DictionaryUtils.js';
import { getWebsite } from 'utils/WebsiteUtils.js';
import { getValueFromParameterMap } from 'cms/NBossCMS.js';

export default function MenuSlider(props) {
    let location = useLocation();

    useEffect(() => {
        if (props.onSliderClose())
            props.onSliderClose()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <motion.div
            className={'fixed top-0 left-0 w-full h-full z-50 ' + (props.className || '')}
            initial={props.open ? 'visible' : 'hidden'}
            animate={props.open ? 'visible' : 'hidden'}
            variants={{ visible: { opacity: 1, display: 'flex' }, hidden: { opacity: 0.9999, transitionEnd: { display: 'none' } } }}>

            <motion.div
                className='absolute w-full h-full bg-white bg-opacity-70'
                initial={props.open ? 'visible' : 'hidden'}
                animate={props.open ? 'visible' : 'hidden'}
                variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }}
                onClick={() => props.onSliderClose()} />

            <div className='absolute right-0 h-full w-3/4 max-w-sm filter'>

                <motion.div
                    className='bg-gyeki-blue shadow-xl h-full flex flex-col items-center gap-6 pt-0 pb-6'
                    initial={props.open ? 'visible' : 'hidden'}
                    animate={props.open ? 'visible' : 'hidden'}
                    style={{ originX: 1, originY: 0.5, transformPerspective: 1250 }}
                    transition={{ duration: 0.35 }}
                    variants={{ visible: { rotateY: 0, display: 'flex' }, hidden: { rotateY: -90, transitionEnd: { display: 'none' } } }}>

                    <div className='relative w-full flex items-center px-6 h-20' >

                        {/* <div className='font-display text-4xl text-white/50 font-bold'>Menü</div> */}

                        <motion.div
                            className=''
                            initial={props.open ? 'visible' : 'hidden'}
                            animate={props.open ? 'visible' : 'hidden'}
                            // style={{ originX: 1, originY: 0.5, transformPerspective: 1250 }}
                            transition={{ duration: 0.35, delay: 0.2 }}
                            variants={{ visible: { opacity: 1, scale: 1 }, hidden: { opacity: 0, scale: 0.75 } }}>
                            <img className='w-48' alt='logo' src='/assets/images/logo-white-hu-HU.svg' />
                        </motion.div>

                        {/* <Link to='/' className='flex-1'>
                            <img className='w-44 pr-2 mb-2' alt='logo' src={'/assets/images/logo-white-' + getLanguage() + '.svg'} />
                        </Link> */}

                        {/* <div className={'flex h-20 items-center justify-center md:hidden z-50 '}>
                            <Hamburger size={25} color="#ffffff" direction="left" toggled={props.open} toggle={() => props.onSliderClose()} />
                        </div> */}
                    </div>

                    <MultiLevelMenu
                        className='w-full flex-1 overflow-y-auto overflow-x-hidden px-6'
                        data={getValueFromParameterMap(getWebsite().globalContent, 'menu')}
                        baseLevel={0}
                        firstLevelKey='itemList'
                        activeClassName='font-semibold'
                        baseClassName='text-white tracking-wider'
                        mainClassName='font-display text-base uppercase'
                        subClassName='text-xs'
                        orbClassName='bg-gyeki-blue'
                        iconClassName='text-white' />



                    {/* <div className='flex flex-col gap-2 items-center justify-center'>
                        <div className='text-xs text-gyeki-gray-dark '>Nyelv - Language</div>
                        <LanguageSwitch />
                    </div> */}

                    <div className='w-full flex-col items-center justify-center text-center text-white font-display text-xxs font-thin leading-5 px-6'>
                        {'2024 © ' + getTranslation('copyright')}<br /> {getTranslation('title')}
                    </div>

                </motion.div>

            </div>

        </motion.div >
    );
}