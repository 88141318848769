import Slideshow from 'components/Slideshow.js';
import DynamicIcon from 'elements/DynamicIcon.js';
import GoogleMapReact from 'google-map-react';
import Sticky from 'react-stickynode';

import Breadcrumb from 'components/Breadcrumb.js';

import globalConfig from 'config/globalConfig.js'
import { getValueFromParameterMap } from 'cms/NBossCMS.js';
import { getWebsite } from 'utils/WebsiteUtils.js';
import { getLanguage } from 'utils/DictionaryUtils.js';

export default function Content(props) {
    let _data = {
        heading: getValueFromParameterMap(props.data, 'heading'),
        subHeading: getValueFromParameterMap(props.data, 'sub-heading'),
        lead: getValueFromParameterMap(props.data, 'lead'),
        content: getValueFromParameterMap(props.data, 'content'),
        map: getValueFromParameterMap(props.data, 'map'),
        slider: getValueFromParameterMap(props.data, 'slider'),
        contentImageBottom: getValueFromParameterMap(props.data, 'content-image-bottom') && getValueFromParameterMap(props.data, 'content-image-bottom').downloadUrl,
        collegueList: getValueFromParameterMap(props.data, 'collegue-list'),
        highlightContentBottom: getValueFromParameterMap(props.data, 'highlight-content-bottom'),
    };

    return (
        <div className='w-full flex flex-col overflow-hidden'>

            {_data.slider && <Sticky enabled={true}><Slideshow data={_data.slider} /></Sticky>}

            <div className='w-full flex flex-col bg-white z-10'>

                {!_data.slider && <div className='p-6 xl:p-8 pb-0 xl:pb-0'><Breadcrumb /></div>}

                <div className='w-full flex flex-col gap-8 p-6 xl:p-8'>

                    {_data.heading && <h1>{_data.heading}</h1>}

                    {_data.subHeading && <h2>{_data.subHeading}</h2>}

                    {_data.lead && <section className='lead' dangerouslySetInnerHTML={{ __html: _data.lead }} />}

                    {_data.content && <section className='content' dangerouslySetInnerHTML={{ __html: _data.content }} />}

                </div>

                <div className='w-full flex flex-col gap-8'>

                    {_data.collegueList && <div className='flex flex-col'>{_data.collegueList.parameterMap.colleague.value.contentList.map((item, index) => (<Colleague key={index} data={item} />))}</div>}

                    {_data.contentImageBottom && <img className='w-full' alt='' src={_data.contentImageBottom} />}

                    {_data.highlightContentBottom && <section className='content px-6 xl:px-8 pb-6 xl:pb-8' dangerouslySetInnerHTML={{ __html: _data.highlightContentBottom }} />}

                </div>

            </div>

            {_data.map && <Map />}

        </div>
    );
}

function Colleague(props) {
    let _data = {
        name: getValueFromParameterMap(props.data, 'name'),
        firstName: getValueFromParameterMap(props.data, 'first-name'),
        lastName: getValueFromParameterMap(props.data, 'last-name'),
        postition: getValueFromParameterMap(props.data, 'postition'),
        introduction: getValueFromParameterMap(props.data, 'introduction'),
        image: getValueFromParameterMap(props.data, 'image') && getValueFromParameterMap(props.data, 'image').downloadUrl,
    };

    return (
        <div className='relative w-full content flex flex-col'>

            {/* <div className='absolute w-1 h-full top-0 left-0 bg-gradient-to-b from-gyeki-gray-lightest' /> */}

            <div className='flex flex-col gap-1 leading-5 bg-neutral-100 p-6 xl:p-8 shadox-xl'>

                <div className='flex flex-row font-semibold text-3xl'>
                    {_data.firstName && <div className={'' + (getLanguage() === 'hu-HU' ? 'order-2' : 'order-1')}>{_data.firstName}&nbsp;</div>}
                    {_data.lastName && <div className={'' + (getLanguage() === 'hu-HU' ? 'order-1' : 'order-2 uppercase')} >{_data.lastName}&nbsp;</div>/*[font-variant:small-caps]*/}
                </div>

                {_data.postition && <div className='text-base text-gyeki-gold capitalize flex items-center gap-1.5'>
                    {/* <div className='rounded-[3px] rotate-45 w-2.5 h-2.5 cursor-pointer transition shadow-xl border-[1.5px] border-gyeki-gold/90' /> */}
                    {_data.postition}
                </div>}

            </div>

            <div className='p-6 xl:p-8'>
                {_data.image && <img className='sm:float-left w-full sm:w-[310px] mt-2 mr-6 mb-3' alt='profile' src={_data.image} />}
                <section dangerouslySetInnerHTML={{ __html: _data.introduction }} />
            </div>

        </div>
    )
}

function Map(props) {
    const mapConfig = getValueFromParameterMap(getWebsite().globalContent, 'map-config');

    const lat = +getValueFromParameterMap(mapConfig, 'lat');
    const lng = +getValueFromParameterMap(mapConfig, 'lng');
    const zoom = +getValueFromParameterMap(mapConfig, 'zoom');

    return (
        <div className='w-full aspect-video shadow-4xl z-10'>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyAxkFnq23HobLVbC2tjcNOcvBsgKTZ8GUs' }}
                defaultCenter={{ lat: lat, lng: lng }}
                defaultZoom={zoom}
                options={{ styles: globalConfig.mapStlyes }}>
                {<MapPointer lat={lat} lng={lng} />}
            </GoogleMapReact>
        </div>
    );
}

function MapPointer(props) {
    return (
        <div className='flex w-0 h-0 items-end justify-center'>
            <DynamicIcon className='text-4xl text-gyeki-blue' iconName={'FaMapMarkerAlt'} />
        </div>
    );
}